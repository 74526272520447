















































































































import PlayerInput from '@/components/NewMatchForm/PlayerInput.vue'
import BaseButton from '@/components/ui/BaseButton.vue'
import BaseCheckbox from '@/components/ui/BaseCheckbox.vue'
import BaseFormField from '@/components/ui/BaseFormField.vue'
import BaseRadio from '@/components/ui/BaseRadioGroup/BaseRadio.vue'
import BaseRadioGroup from '@/components/ui/BaseRadioGroup/Index.vue'
import BaseSelect from '@/components/ui/BaseSelect.vue'
import { games } from '@/data/games'
import Game from '@/model/new-match/game'
import NewMatch from '@/model/new-match/new-match'
import Player from '@/model/new-match/player'
import Vue from 'vue'

export default Vue.extend({
  name: 'NewMatchPanel',

  components: {
    BaseButton,
    BaseCheckbox,
    BaseFormField,
    BaseRadio,
    BaseRadioGroup,
    BaseSelect,
    PlayerInput
  },

  data () {
    return {
      players: [new Player('Player 1'), new Player('Player 2')] as Array<Player>,
      gameCode: null as string | null,
      options: {} as { [code: string]: string },
      games: games as Array<Game>
    }
  },

  computed: {
    game (): Game | undefined | null {
      if (this.gameCode != null) {
        return this.games.find(game => game.code === this.gameCode)
      }
      return null
    },

    nextPlayerName (): string {
      return `Player ${this.players.reduce((accumulator: number, player: Player) => accumulator + player.members.length, 0) + 1}`
    }
  },

  methods: {
    addPlayer () {
      this.players.push(new Player(this.nextPlayerName))
    },

    removePlayer (playerIndex: number) {
      this.players.splice(playerIndex, 1)
    },

    resetOptions () {
      this.options = {}

      if ((this.game != null) && (this.game.options != null)) {
        this.game.options.forEach(option => {
          if (option.defaultValue != null) {
            this.options[option.code] = option.defaultValue
          }
        })
      }
    },

    submit () {
      if (
        this.players.every(player => player.members.every(member => ((member.name != null) && (member.name !== '')))) &&
        (this.gameCode != null) &&
        (this.game != null) &&
        ((this.game.options == null) || this.game.options.every(option => !option.required || (this.options[option.code] != null)))
      ) {
        this.$emit('start', new NewMatch(this.players, this.gameCode, this.options))
      }
    }
  }
})
