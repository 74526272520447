















import Vue from 'vue'

export default Vue.extend({
  name: 'BaseButton',

  props: {
    type: {
      type: String,
      default: 'button'
    },
    small: {
      type: Boolean,
      default: false
    },
    icon: {
      type: Boolean,
      default: false
    },
    outlined: {
      type: Boolean,
      default: false
    },
    flat: {
      type: Boolean,
      default: false
    },
    color: {
      type: String,
      default: undefined
    }
  },

  computed: {
    cssClasses (): Array<string> {
      let cssClasses = []
      if (this.icon) {
        if (this.small) {
          cssClasses.push('tw-w-8')
        } else {
          cssClasses.push('tw-w-10')
        }
      } else if (!this.flat) {
        cssClasses.push('tw-px-4')
      }
      if (this.small) {
        cssClasses = cssClasses.concat(['tw-h-8', 'tracking-normal', 'tw-text-base', 'tw-leading-4'])
      } else {
        cssClasses = cssClasses.concat(['tw-h-10', 'tracking-wide', 'tw-text-xl'])
      }
      if (!this.flat) {
        cssClasses = cssClasses.concat(['tw-py-1', 'tw-rounded-full'])
      }

      return cssClasses
    }
  }
})
