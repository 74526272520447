import serializable from '@/decorators/serializable'
import Target from '@/model/match/target'

@serializable
export default class BullTarget extends Target {
  constructor () {
    super()

    this.section = 25
  }
}
