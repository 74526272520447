import Board from '@/model/board'
import Digit from '@/model/board/digit'
import Led from '@/model/board/led'

export const board: Board = {
  throws: [new Led(5, 5, 6), new Led(5, 1, 6), new Led(5, 7, 6)],
  round: [new Digit(1, 1), new Digit(1, 5)],
  tempScore: [new Digit(2, 1), new Digit(2, 5), new Digit(2, 7)],
  targets: [
    [new Digit(3, 2), new Digit(3, 6)],
    [new Digit(3, 4), new Digit(3, 0)],
    [new Digit(3, 1), new Digit(3, 5)],
    [new Digit(3, 7), new Digit(3, 3)],
    [new Digit(2, 3), new Digit(2, 2)],
    [new Digit(2, 6), new Digit(2, 4)]
  ],
  bull: [
    new Led(4, 3, 1),
    new Led(5, 0, 7),
    new Led(5, 1, 7),
    new Led(5, 3, 7),
    new Led(5, 4, 7),
    new Led(5, 5, 7),
    new Led(5, 7, 7)
  ],
  players: [
    // Player 1
    {
      active: [new Led(5, 5, 1), new Led(5, 0, 3)],
      score: [new Digit(1, 7), new Digit(1, 3), new Digit(1, 2)],
      targetsThrows: [
        [new Led(4, 0, 5), new Led(4, 0, 0), new Led(4, 0, 4)],
        [new Led(5, 0, 2), new Led(5, 0, 6), new Led(5, 0, 1)],
        [new Led(4, 0, 6), new Led(4, 0, 2), new Led(4, 0, 7)],
        [new Led(5, 0, 4), new Led(5, 0, 0), new Led(5, 0, 5)],
        [new Led(4, 3, 4), new Led(4, 3, 0), new Led(4, 3, 5)],
        [new Led(5, 3, 5), new Led(5, 3, 0), new Led(5, 3, 4)],
        [new Led(4, 3, 7), new Led(4, 3, 2), new Led(4, 3, 6)]
      ]
    },
    // Player 2
    {
      active: [new Led(5, 3, 1), new Led(5, 4, 3)],
      score: [new Digit(1, 4), new Digit(1, 0), new Digit(1, 6)],
      targetsThrows: [
        [new Led(4, 4, 5), new Led(4, 4, 0), new Led(4, 4, 4)],
        [new Led(5, 4, 2), new Led(5, 4, 6), new Led(5, 4, 1)],
        [new Led(4, 4, 6), new Led(4, 4, 2), new Led(4, 4, 7)],
        [new Led(5, 4, 4), new Led(5, 4, 0), new Led(5, 4, 5)],
        [new Led(4, 7, 4), new Led(4, 7, 0), new Led(4, 7, 5)],
        [new Led(5, 7, 5), new Led(5, 7, 0), new Led(5, 7, 4)],
        [new Led(4, 7, 7), new Led(4, 7, 2), new Led(4, 7, 6)]
      ]
    },
    // Player 3
    {
      active: [new Led(5, 1, 1), new Led(5, 6, 3)],
      score: [new Digit(0, 6), new Digit(0, 2), new Digit(0, 3)],
      targetsThrows: [
        [new Led(4, 6, 5), new Led(4, 6, 0), new Led(4, 6, 4)],
        [new Led(5, 6, 2), new Led(5, 6, 6), new Led(5, 6, 1)],
        [new Led(4, 6, 6), new Led(4, 6, 2), new Led(4, 6, 7)],
        [new Led(5, 6, 4), new Led(5, 6, 0), new Led(5, 6, 5)],
        [new Led(4, 5, 4), new Led(4, 5, 0), new Led(4, 5, 5)],
        [new Led(5, 5, 5), new Led(5, 5, 0), new Led(5, 5, 4)],
        [new Led(4, 5, 7), new Led(4, 5, 2), new Led(4, 5, 6)]
      ]
    },
    // Player 4
    {
      active: [new Led(5, 7, 1), new Led(5, 2, 3)],
      score: [new Digit(0, 1), new Digit(0, 5), new Digit(0, 7)],
      targetsThrows: [
        [new Led(4, 2, 5), new Led(4, 2, 0), new Led(4, 2, 4)],
        [new Led(5, 2, 2), new Led(5, 2, 6), new Led(5, 2, 1)],
        [new Led(4, 2, 6), new Led(4, 2, 2), new Led(4, 2, 7)],
        [new Led(5, 2, 4), new Led(5, 2, 0), new Led(5, 2, 5)],
        [new Led(4, 1, 4), new Led(4, 1, 0), new Led(4, 1, 5)],
        [new Led(5, 1, 5), new Led(5, 1, 0), new Led(5, 1, 4)],
        [new Led(4, 1, 7), new Led(4, 1, 2), new Led(4, 1, 6)]
      ]
    }
  ]
}
