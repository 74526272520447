import '@/assets/styles/index.scss'
import Vue from 'vue'
import App from './App.vue'
import store from './store'

Vue.config.productionTip = false

new Vue({
  store,
  render: h => h(App)
}).$mount('#app')

if (process.env.VUE_APP_BLUETOOTH === 'true') {
  store.dispatch('board/init')
}

window.addEventListener('load', function () {
  const viewport = document.querySelector('meta[name=viewport]')
  // eslint-disable-next-line dot-notation
  viewport.setAttribute('content', `${viewport['content']}, height=${window.innerHeight}`)
})
