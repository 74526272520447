import Element from './element'

export default class Command {
  public element: Element
  public value: string | boolean

  constructor (element: Element, value: string | boolean) {
    this.element = element
    this.value = value
  }

  public isEmpty (): boolean {
    return typeof this.value === 'boolean' ? !this.value : (this.value === ' ')
  }

  public toString (): string {
    let str = this.element.toString()

    if (typeof this.value === 'boolean') {
      str += this.value ? '1' : '0'
    } else {
      str += this.value.toString()
    }

    return str
  }
}
