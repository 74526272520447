import { board } from '@/data/board'
import Command from '@/model/board/command'
import Digit from '@/model/board/digit'
import Element from '@/model/board/element'
import Led from '@/model/board/led'
import Match from '@/model/match'
import Player from '@/model/match/player'
import SectionTarget from '@/model/match/section-target'
import { RootState } from '@/store'
import { cloneDeep } from 'lodash'
import { from, NEVER, ObservableInput, Subject, timer } from 'rxjs'
import { concatMap, map, repeat, switchMap } from 'rxjs/operators'
import { ActionContext } from 'vuex'

const cache: Map<Element, string | boolean> = new Map<
  Element,
  string | boolean
>()

function optimize (commands: Array<Command>): Array<Command | string> {
  let commandsOptimized = []

  commands.forEach(command => {
    const previousValue = cache.get(command.element)

    if (previousValue !== command.value) {
      if (command.isEmpty()) {
        cache.delete(command.element)
      } else {
        cache.set(command.element, command.value)
      }

      if (previousValue != null || !command.isEmpty()) {
        commandsOptimized.push(command)
      }
    }
  })

  if (commandsOptimized.length >= cache.size) {
    commandsOptimized = ['r']

    cache.forEach((value, element) => {
      commandsOptimized.push(new Command(element, value))
    })
  }

  return commandsOptimized
}

function getLedsCommands (leds: Array<Led>, value): Array<Command> {
  const commands = []

  if (value == null) {
    value = 0
  }

  for (let i = 0; i < value; i++) {
    commands.push(new Command(leds[i], true))
  }
  for (let i = value; i < leds.length; i++) {
    commands.push(new Command(leds[i], false))
  }

  return commands
}

function getDigitsCommands (digits: Array<Digit>, value: string | number): Array<Command> {
  return [...(value == null ? '' : value).toString().padStart(digits.length)].map((char, i) => new Command(digits[i], char))
}

function getPlayerActiveCommands (playerIndex: number, active: boolean): Array<Command> {
  return board.players[playerIndex].active.map(led => new Command(led, active))
}

const blinking = from(Array.from([false, true]))
  .pipe(concatMap(value => timer(285).pipe(map(() => value))))
  .pipe(repeat())

const playersAnimations = [...Array(4)].map(() => {
  return {
    endingRound: new Subject<ObservableInput<boolean>>()
  }
})

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const commandsStream = new Subject<any>()
commandsStream.pipe(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  concatMap((write: any) => write()))
  .subscribe()

let oldMatch: Match = new Match()

// eslint-disable-next-line @typescript-eslint/ban-types
export type State = {}

export default ({
  namespaced: true,

  state: {} as State,

  getters: {},

  mutations: {},

  actions: {
    init ({ dispatch }: ActionContext<State, RootState>): void {
      playersAnimations.forEach((playerAnimations, index) => {
        playerAnimations.endingRound.pipe(switchMap(value => value)).subscribe(value => {
          dispatch('addCommands', getPlayerActiveCommands(index, value))
        })
      })
    },

    updateMatch ({ dispatch, rootGetters }: ActionContext<State, RootState>): void {
      if (rootGetters['bluetooth/connectedDevice'] != null) {
        const newMatch = rootGetters['match/match']

        let commands: Array<Command> = []

        if (newMatch.throws !== oldMatch.throws) {
          commands = commands.concat(getLedsCommands(board.throws, newMatch.throws))
        }

        if (newMatch.tempScore !== oldMatch.tempScore) {
          commands = commands.concat(getDigitsCommands(board.tempScore, newMatch.tempScore))
        }

        if (newMatch.roundTarget == null) {
          if (newMatch.round !== oldMatch.round) {
            commands = commands.concat(getDigitsCommands(board.round, newMatch.round))
          }
        } else {
          if (newMatch.roundTarget !== oldMatch.roundTarget) {
            let text = ''
            if (newMatch.roundTarget.section != null) {
              text = newMatch.roundTarget.section.toString()
            } else {
              switch (newMatch.roundTarget.coefficient) {
                case 2:
                  text = 'db'
                  break
                case 3:
                  text = 'tp'
                  break
              }
            }

            commands = commands.concat(getDigitsCommands(board.round, text))
          }
        }

        newMatch.targets.forEach((newTarget, index) => {
          const oldTarget = oldMatch.targets[index]

          if (newTarget !== oldTarget) {
            if (index < 6) {
              if (newTarget.drawing !== (oldTarget as SectionTarget).drawing) {
                if (!newTarget.drawing) {
                  commands = commands.concat(getDigitsCommands(board.targets[index], !newTarget.active ? null : newTarget.section))
                }
              } else {
                commands = commands.concat(getDigitsCommands(board.targets[index], !newTarget.active ? null : newTarget.section))
              }
            } else {
              for (const led of board.bull) {
                commands.push(new Command(led, newTarget.active))
              }
            }
          }
        })

        for (let index = 0; index < Math.min(Math.max(newMatch.players.length, oldMatch.players.length), 4); index++) {
          const newPlayer = newMatch.players[index] || new Player()
          const oldPlayer = oldMatch.players[index] || new Player()

          if (newPlayer !== oldPlayer) {
            if (newPlayer.endingRound !== oldPlayer.endingRound) {
              if (newPlayer.endingRound) {
                playersAnimations[index].endingRound.next(blinking)
              } else {
                playersAnimations[index].endingRound.next(NEVER)
                commands = commands.concat(getPlayerActiveCommands(index, newPlayer.active))
              }
            } else if (newPlayer.active !== oldPlayer.active) {
              playersAnimations[index].endingRound.next(NEVER)
              commands = commands.concat(getPlayerActiveCommands(index, newPlayer.active))
            }

            if (newPlayer.rank != null) {
              commands = commands.concat(getDigitsCommands(board.players[index].score, `-${newPlayer.rank}-`))
            } else if ((oldPlayer.rank != null) || (newPlayer.score.value !== oldPlayer.score.value)) {
              commands = commands.concat(getDigitsCommands(board.players[index].score, newPlayer.score.value))
            }

            newPlayer.hits.forEach((newHit: number, hitIndex: number) => {
              const oldHit = oldPlayer.hits[hitIndex]

              if (newHit !== oldHit) {
                commands = commands.concat(getLedsCommands(board.players[index].targetsThrows[hitIndex], newHit))
              }
            })
          }
        }

        oldMatch = cloneDeep(newMatch)

        if (commands.length > 0) {
          dispatch('addCommands', optimize(commands))
        }
      }
    },

    addCommands ({ dispatch }: ActionContext<State, RootState>, commands: Array<Command | string>): void {
      dispatch('bluetooth/write', commands.join(''), { root: true })
    },

    reset ({ dispatch }: ActionContext<State, RootState>): void {
      cache.clear()
      oldMatch = new Match()

      dispatch('updateMatch')
    }
  }
})
