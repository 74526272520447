

































import BaseButton from '@/components/ui/BaseButton.vue'
import LoadingBar from '@/components/ui/LoadingBar.vue'
import LoadingCircle from '@/components/ui/LoadingCircle.vue'
import Device from '@/model/bluetooth/device'
import Vue from 'vue'
import { mapGetters } from 'vuex'

export default Vue.extend({
  name: 'Bluetooth',

  components: {
    BaseButton,
    LoadingBar,
    LoadingCircle
  },

  data () {
    return {
      selectedDevice: null as null | Device
    }
  },

  computed: {
    ...mapGetters({
      devices: 'bluetooth/devices',
      scanning: 'bluetooth/scanning',

      connectedDevice: 'bluetooth/connectedDevice',
      connecting: 'bluetooth/connecting'
    })
  },

  methods: {
    scan (): void {
      this.selectedDevice = null

      this.$store.dispatch('bluetooth/scan')
    },

    connect (device: Device): void {
      this.selectedDevice = device

      this.$store.dispatch('bluetooth/connect', device)
    }

  }
})
