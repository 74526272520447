import Match from '@/model/match'
import Hit from '@/model/match/hit'
import Player from '@/model/match/player'
import { uniq } from 'lodash'
import { Observable, of, Subject, Subscription, timer } from 'rxjs'
import { switchMap } from 'rxjs/operators'

export default class Game {
  public code: string
  public options: Record<string, string | boolean>

  public match: Match

  public deactivePlayerSubject: Subject<boolean> = new Subject()
  public deactivePlayerSubscription: Subscription
  private deactivePlayerObservable: Observable<number> = this.deactivePlayerSubject.pipe(switchMap(value => {
    return value ? timer(2000) : of(null)
  }))

  public resume (options: Record<string, string | boolean>, match: Match): void {
    this.options = options

    this.match = match

    if (this.match.waitingForNextPlayer) {
      this.startPlayerRound()
    } else if (this.match.waitingForNextRound) {
      this.nextRound()
    }
  }

  public start (match: Match): void {
    this.match = match

    this.match.round = 1
    this.initPlayers()

    this.init()
  }

  public shoot (hit: Hit): void {
    this.match.hits.push(hit)

    this.match.throws--

    if (this.match.hit !== null) {
      this.match.tempScore += this.match.hit.getTotal()
    }

    this.hit()
  }

  protected init (): void {
    this.startRound()
  }

  protected initPlayers (): void {
    this.match.players.forEach(player => {
      player.score.value = 0
    })
  }

  protected startRound (): void {
    this.startPlayerRound()
  }

  protected startPlayerRound (): void {
    this.match.nextPlayer()

    this.match.activePlayer.active = true
    this.match.activePlayer.score.init()

    this.match.hits = []
    this.match.throws = 3
    this.match.tempScore = 0

    this.match.message = this.match.activePlayer.name
  }

  protected hit (): void {
    if (this.match.throws === 0) {
      this.endPlayerRound()
    }
  }

  public endPlayerRound (manually = false): void {
    this.match.activePlayer.endingRound = true
    this.match.activePlayer.active = false
    this.match.activePlayer.nextMember()

    this.deactivePlayerSubscription = this.deactivePlayerObservable.subscribe(() => {
      this.deactivePlayerSubscription.unsubscribe()

      this.match.activePlayer.endingRound = false
      if (this.match.isEndRound) {
        this.nextRound()
      } else {
        this.startPlayerRound()
      }
    })

    this.deactivePlayerSubject.next(!manually)
  }

  private nextRound (): void {
    this.match.round++
    this.startRound()
  }

  protected setWinner (player: Player): void {
    player.rank = this.match.winners.length + 1

    this.checkEndGame()
  }

  protected checkEndGame (): void {
    switch (this.match.otherPlayers.length) {
      case 0:
        break
      case 1:
        this.match.otherPlayers[0].rank = this.match.winners.length + 1
        break
      default:
        this.endPlayerRound()
    }
  }

  protected resetPlayerScore (player: Player, score: number): void {
    player.score.reset = true
    player.score.value = score

    setTimeout(() => {
      player.score.reset = false
    })
  }

  protected orderWinnersByHighestScore (): void {
    const orderedScores = uniq(this.match.players.map((player: Player) => player.score.value).sort()).reverse()

    this.match.players.forEach((player: Player) => {
      player.rank = orderedScores.indexOf(player.score.value) + 1
    })
  }
}
