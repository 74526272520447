import Vue from 'vue'
import Vuex from 'vuex'
import bluetooth, { State as BluetoothState } from './modules/bluetooth'
import board, { State as BoardState } from './modules/board'
import match, { State as MatchState } from './modules/match'

Vue.use(Vuex)

export type RootState = {
  bluetooth: BluetoothState
  board: BoardState
  match: MatchState
}

export default new Vuex.Store({
  modules: {
    match,
    bluetooth,
    board
  }
  // strict: process.env.NODE_ENV !== 'production'
})
