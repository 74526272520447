























import Vue from 'vue'

export default Vue.extend({
  name: 'Dialog',

  props: {
    value: {
      type: Boolean,
      default: false
    }
  }
})
