import Element from './element'

export default class Led extends Element {
  public row: number
  public col: number

  constructor (addr: number, row: number, col: number) {
    super(addr)

    this.row = row
    this.col = col
  }

  public toString (): string {
    return `l${String(this.addr)}${String(this.row)}${String(this.col)}`
  }
}
