import Area from '@/model/match/area'
import TargetRoundGame from './target-round-game'

export default class BurmaRoad extends TargetRoundGame {
  constructor () {
    super()

    this.roundTargets = [
      new Area(15),
      new Area(16),
      new Area(null, 2),
      new Area(17),
      new Area(18),
      new Area(null, 3),
      new Area(19),
      new Area(20),
      new Area(25)
    ]
    this.numberOfRounds = this.roundTargets.length
  }

  protected initPlayers (): void {
    this.match.players.forEach(player => {
      player.score.value = 40
    })
  }

  protected hit (): void {
    if (this.match.hit.match(this.match.roundTarget)) {
      this.match.activePlayer.score.add(this.match.hit.getTotal())
    }

    super.hit()
  }

  public endPlayerRound (): void {
    if (!this.match.hits.some(hit => hit.match(this.match.roundTarget))) {
      this.resetPlayerScore(this.match.activePlayer, Math.ceil(
        this.match.activePlayer.score.value / 2
      ))
    }

    super.endPlayerRound()
  }
}
