






import Vue from 'vue'
import BaseRadio from './BaseRadio.vue'

export default Vue.extend({
  name: 'BaseRadioGroup',

  props: {
    value: {
      type: String,
      default: null
    }
  },

  data () {
    return {
      radios: {} as { [code: string]: InstanceType<typeof BaseRadio> },
      currentValue: null as null | string
    }
  },

  mounted () {
    for (const radio of this.$children as Array<InstanceType<typeof BaseRadio>>) {
      radio.$on('click', () => {
        this.select(radio.value)
      })
      this.radios[radio.value] = radio
    }

    if (this.value != null) {
      this.currentValue = this.value
      this.select(this.value)
    }
  },

  methods: {
    select (value: string) {
      if (this.currentValue != null) {
        const currentRadio = this.radios[this.currentValue]
        if (currentRadio != null) {
          currentRadio.active = false
        }
      }

      if (this.currentValue === value) {
        this.currentValue = null
        this.$emit('input', null)
      } else {
        const selectedRadio = this.radios[value]
        if (selectedRadio != null) {
          selectedRadio.active = true

          this.currentValue = value
          this.$emit('input', this.currentValue)
        }
      }
    }
  }
})
