import Area from '@/model/match/area'
import FixRoundGame from './fixed-rounds-game'

export default class TargetRoundGame extends FixRoundGame {
  protected roundTargets: Array<Area>

  protected startRound (): void {
    this.match.roundTarget = this.roundTargets[this.match.round - 1]

    super.startRound()
  }
}
