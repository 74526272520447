import serializable from '@/decorators/serializable'
import { Score as BaseScore } from 'scoreboard'

@serializable
export default class Score implements BaseScore {
  public value: number = null
  public initial: number = null
  public reset = false

  public init (): void {
    this.initial = this.value
  }

  public add (points: number): void {
    this.value += points
  }
}
