import Match from '@/model/match'
import Hit from '@/model/match/hit'
import Player from '@/model/match/player'
import NewMatch from '@/model/new-match/new-match'
import Game from '@/services/games/game'
import GameFactory from '@/services/games/game-factory'
import { RootState } from '@/store'
import { parse, stringify } from '@/utils/json'
import { cloneDeep } from 'lodash'
import { ActionContext } from 'vuex'

export type State = {
  match: Match
  history: Array<Match>
}

let game: Game | null = null

export default {
  namespaced: true,

  state: {
    match: new Match(),
    history: []
  } as State,

  getters: {
    match: (state: State): Match => state.match,
    history: (state: State): Array<Match> => state.history
  },

  mutations: {},

  actions: {
    start ({ state }: ActionContext<State, RootState>, newMatch: NewMatch): void {
      game = GameFactory.getGame(newMatch.gameCode)
      if (game != null) {
        state.history = []

        game.options = newMatch.options

        state.match = new Match(newMatch.players.map(
          (newPlayer) =>
            new Player(newPlayer.members.map((member) => member.name))
        ))

        game.start(state.match)
      }
    },

    load ({ state, dispatch }: ActionContext<State, RootState>): void {
      if (window.localStorage.getItem('match-backup') != null) {
        try {
          const { gameCode, options, match, history } = parse(window.localStorage.getItem('match-backup'))

          state.match = match
          state.history = history

          game = GameFactory.getGame(gameCode)
          if (game != null) {
            game.resume(options, match)
          }
        } catch (error) {
          dispatch('reset')
        }
      }
    },

    save ({ state }: ActionContext<State, RootState>): void {
      if (state.match.started && (game != null)) {
        window.localStorage.setItem(
          'match-backup',
          stringify({
            match: state.match,
            history: state.history,
            gameCode: game.code,
            options: game.options
          })
        )
      } else if (state.match.finished) {
        window.localStorage.removeItem('match-backup')
      }
    },

    reset ({ state }: ActionContext<State, RootState>): void {
      state.match = new Match()
      state.history = []
      game = null

      window.localStorage.removeItem('match-backup')
    },

    nextPlayer ({ state }: ActionContext<State, RootState>): void {
      if (game != null) {
        if (
          game.deactivePlayerSubject.observers != null &&
          game.deactivePlayerSubject.observers.length
        ) {
          game.deactivePlayerSubject.next(false)
        } else {
          state.history.push(cloneDeep(state.match))
          game.endPlayerRound(true)
        }
      }
    },

    cancel ({ state }: ActionContext<State, RootState>): void {
      if (game != null) {
        if (
          game.deactivePlayerSubject.observers != null &&
          game.deactivePlayerSubject.observers.length
        ) {
          game.deactivePlayerSubscription.unsubscribe()
        }
        state.match = state.history.pop() as Match
        game.match = state.match
      }
    },

    shoot ({ state }: ActionContext<State, RootState>, hit: Hit): void {
      if (game != null) {
        if (
          game.deactivePlayerSubject.observers != null &&
          game.deactivePlayerSubject.observers.length
        ) {
          game.deactivePlayerSubject.next(false)
        } else {
          state.history.push(cloneDeep(state.match))

          game.shoot(hit)
        }
      }
    }
  }
}
