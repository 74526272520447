import Element from './element'

export default class Digit extends Element {
  public position: number

  constructor (addr: number, position: number) {
    super(addr)

    this.position = position
  }

  public toString (): string {
    return `d${String(this.addr)}${String(this.position)}`
  }
}
