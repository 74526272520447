import FixRoundGame from './fixed-rounds-game'

export default class More extends FixRoundGame {
  protected init (): void {
    this.numberOfRounds = +this.options.NUMBER_OF_ROUNDS

    super.init()
  }

  protected hit (): void {
    if ((this.match.hits.length > 1) && (this.match.hits[this.match.hits.length - 1].getTotal() <= this.match.hits[this.match.hits.length - 2].getTotal())) {
      this.resetPlayerScore(this.match.activePlayer, this.match.activePlayer.score.initial)

      this.endPlayerRound()
    } else {
      this.match.activePlayer.score.value += this.match.hit.getTotal()

      super.hit()
    }
  }
}
