import { render, staticRenderFns } from "./LoadingBar.vue?vue&type=template&id=637b7b86&scoped=true&"
import script from "./LoadingBar.vue?vue&type=script&lang=ts&"
export * from "./LoadingBar.vue?vue&type=script&lang=ts&"
import style0 from "./LoadingBar.vue?vue&type=style&index=0&id=637b7b86&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "637b7b86",
  null
  
)

export default component.exports