



























import Vue from 'vue'

export default Vue.extend({
  name: 'BaseInput',

  props: {
    placeholder: {
      type: String,
      default: undefined
    },
    type: {
      type: String,
      default: 'text'
    },
    value: {
      type: String,
      default: null
    }
  },

  data () {
    return {
      focused: false as boolean
    }
  },

  methods: {
    focus (): void {
      const input = this.$refs.input as HTMLElement
      input.focus()
    },

    onBlur (event: Event): void {
      this.focused = false
      this.$emit('blur', event)
    },

    onFocus (event: Event): void {
      this.focused = true
      this.$emit('focus', event)
    }
  }
})
