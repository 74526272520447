import Area from '@/model/match/area'
import TargetRoundGame from './target-round-game'

export default class Shanghai extends TargetRoundGame {
  private static shanghaiTargets: Array<Area> = [
    new Area(null, 1),
    new Area(null, 2),
    new Area(null, 3)
  ]

  constructor () {
    super()

    this.roundTargets = [
      new Area(1),
      new Area(2),
      new Area(3),
      new Area(4),
      new Area(5),
      new Area(6),
      new Area(7)
    ]
    this.numberOfRounds = this.roundTargets.length
  }

  protected hit (): void {
    if (this.match.hit.match(this.match.roundTarget)) {
      this.match.activePlayer.score.add(this.match.hit.getTotal())
    }

    super.hit()
  }

  public endPlayerRound (manually = false): void {
    if ((this.match.hits.length === 3) && this.hasShanghai()) {
      this.setWinner(this.match.activePlayer)
    } else {
      super.endPlayerRound(manually)
    }
  }

  private hasShanghai () {
    return Shanghai.shanghaiTargets.every(target => this.match.hits.some(hit => hit.match(target)))
  }
}
