import Match from '@/model/match'
import Area from '@/model/match/area'
import Hit from '@/model/match/hit'
import Player from '@/model/match/player'
import Score from '@/model/match/score'
import Target from '@/model/match/target'

export function stringify (value: unknown): string {
  return JSON.stringify(value)
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function instanciate (value: any): any {
  if (Array.isArray(value)) {
    return value.map(item => instanciate(item))
  }

  if ((value != null) && (typeof value === 'object')) {
    for (const [property, propertyValue] of Object.entries(value)) {
      value[property] = instanciate(propertyValue)
    }

    if (value._class != null) {
      let instance
      switch (value._class) {
        case 'Area':
          instance = new Area()
          break
        case 'Hit':
          instance = new Hit()
          break
        case 'Match':
          instance = new Match()
          break
        case 'Player':
          instance = new Player()
          break
        case 'Score':
          instance = new Score()
          break
        case 'Target':
          instance = new Target()
          break
      }
      if (instance != null) {
        value = Object.assign(instance, value)
      }
    }
  }

  return value
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function parse (text: string): any {
  const value = JSON.parse(text)

  return instanciate(value)
}
