import FixRoundGame from './fixed-rounds-game'

export default class HighScore extends FixRoundGame {
  constructor () {
    super()

    this.numberOfRounds = 10
  }

  protected hit (): void {
    this.match.activePlayer.score.add(this.match.hit.getTotal())

    super.hit()
  }
}
