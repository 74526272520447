



















import Vue from 'vue'

export default Vue.extend({
  name: 'BaseSelect',

  props: {
    value: {
      type: String,
      default: null
    }
  }
})
