















import Vue from 'vue'

export default Vue.extend({
  name: 'BaseRadio',

  props: {
    value: {
      type: String,
      default: null
    },
    label: {
      type: String,
      default: ''
    }
  },

  data () {
    return {
      active: false as boolean
    }
  }
})
