import serializable from '@/decorators/serializable'
import Area from '@/model/match/area'
import BullTarget from '@/model/match/bull-target'
import Hit from '@/model/match/hit'
import Player from '@/model/match/player'
import SectionTarget from '@/model/match/section-target'
import { Match as BaseMatch } from 'scoreboard'

@serializable
export default class Match implements BaseMatch {
  public players: Array<Player>

  public throws: number = null
  public player: number = null
  public round: number = null

  public tempScore: number = null
  public roundTarget: Area = null

  public hits: Array<Hit>
  public targets: [SectionTarget, SectionTarget, SectionTarget, SectionTarget, SectionTarget, SectionTarget, BullTarget]

  public message = null

  constructor (players: Array<Player> = []) {
    this.players = players

    this.hits = []
    this.targets = [
      new SectionTarget(),
      new SectionTarget(),
      new SectionTarget(),
      new SectionTarget(),
      new SectionTarget(),
      new SectionTarget(),
      new BullTarget()
    ]
  }

  public get waitingForNextPlayer (): boolean {
    return (this.activePlayer != null) && this.activePlayer.endingRound
  }

  public get waitingForNextRound (): boolean {
    return this.isEndRound && this.waitingForNextPlayer
  }

  public get started (): boolean {
    return this.round != null
  }

  public get finished (): boolean {
    return this.players.every(player => player.rank != null)
  }

  public get activePlayer (): Player {
    return this.players[this.player]
  }

  public get lastPlayer (): Player {
    return this.inGamePlayers[this.inGamePlayers.length - 1]
  }

  public get isEndRound (): boolean {
    return (this.activePlayer === this.lastPlayer)
  }

  public get inGamePlayers (): Array<Player> {
    return this.players.filter((player, index) => ((this.player === index) || (player.rank == null)))
  }

  public get otherPlayers (): Array<Player> {
    if (this.player == null) {
      return this.inGamePlayers
    }

    return [
      ...this.inGamePlayers.slice(this.player + 1),
      ...this.inGamePlayers.slice(0, this.player)
    ]
  }

  public get winners (): Array<Player> {
    return this.players.filter(player => player.rank != null)
  }

  public get hit (): Hit {
    if (this.hits[3 - this.throws - 1] != null) {
      return this.hits[3 - this.throws - 1]
    }

    return null
  }

  public get sectionTargets (): Array<SectionTarget> {
    return [
      this.targets[0],
      this.targets[1],
      this.targets[2],
      this.targets[3],
      this.targets[4],
      this.targets[5]
    ]
  }

  public get randomizeTargets (): Array<SectionTarget> {
    return this.sectionTargets.filter(target => target.drawing)
  }

  public nextPlayer (): void {
    this.player = this.players.indexOf(this.otherPlayers[0])
  }

  public clone (match: Match): void {
    this.players = match.players

    this.throws = match.throws
    this.player = match.player
    this.round = match.round

    this.tempScore = match.tempScore
    this.roundTarget = match.roundTarget

    this.hits = match.hits
    this.targets = match.targets

    this.message = match.message
  }
}
