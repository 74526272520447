import serializable from '@/decorators/serializable'
import Area from '@/model/match/area'

@serializable
export default class Hit extends Area {
  public x: number
  public y: number

  constructor (
    section: number = null,
    coefficient: number = null,
    x: number = null,
    y: number = null
  ) {
    super(section, coefficient)

    this.x = x
    this.y = y
  }

  match (target: Area): boolean {
    return (
      (target.section == null || this.section === target.section) &&
      (target.coefficient == null || this.coefficient === target.coefficient)
    )
  }
}
