import Device from '@/model/bluetooth/device'

export default {
  scan: (): Promise<Array<Device>> => new Promise((resolve, reject) => {
    if (process.env.NODE_ENV !== 'development') {
      window.bluetoothSerial.list(resolve, reject)
    } else {
      setTimeout(() => {
        resolve([
          {
            class: 13,
            name: 'toto',
            id: 'toto',
            address: 'toto'
          },
          {
            class: 133,
            name: 'toto',
            id: 'totddddo',
            address: 'toto'
          },
          {
            class: 1344,
            name: 'toto',
            id: 'toddto',
            address: 'toto'
          },
          {
            class: 13333,
            name: 'toto',
            id: 'totdddddo',
            address: 'toto'
          }
        ] as Array<Device>)
      }, 2000)
    }
  }),

  connect: (id: string): Promise<void> => new Promise<void>((resolve, reject) => {
    if (process.env.NODE_ENV !== 'development') {
      window.bluetoothSerial.connect(id, resolve, reject)
    } else {
      setTimeout(() => {
        resolve()
      }, 2000)
    }
  }),

  write: (message: string): Promise<void> => new Promise<void>((resolve, reject) => {
    console.log(message)
    if (process.env.VUE_APP_BLUETOOTH === 'true') {
      try {
        if (process.env.NODE_ENV !== 'development') {
          window.bluetoothSerial.write(message, resolve, reject)
        } else {
          setTimeout(() => {
            resolve()
          }, 10)
        }
      } catch (error) {
        reject(error)
      }
    } else {
      resolve()
    }
  })
}
