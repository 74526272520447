import bluetoothApi from '@/api/bluetooth'
import Device from '@/model/bluetooth/device'
import { RootState } from '@/store'
import { ActionContext } from 'vuex'

declare global {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  interface Window { bluetoothSerial: any }
}

export type State = {
  devices: Array<Device>
  scanning: boolean

  connectedDevice: Device | null
  connecting: boolean
}

export default {
  namespaced: true,

  state: {
    devices: [],
    scanning: false,

    connectedDevice: null,
    connecting: false
  } as State,

  getters: {
    devices: (state: State): Array<Device> => state.devices,
    scanning: (state: State): boolean => state.scanning,

    connectedDevice: (state: State): Device => state.connectedDevice,
    connecting: (state: State): boolean => state.connecting
  },

  mutations: {
    SET_DEVICES (state: State, devices: Array<Device>): void {
      state.devices = devices
    },
    SET_SCANNING (state: State, scanning: boolean): void {
      state.scanning = scanning
    },

    SET_CONNECTED_DEVICE (state: State, connectedDevice: Device): void {
      state.connectedDevice = connectedDevice
    },
    SET_CONNECTING (state: State, connecting: boolean): void {
      state.connecting = connecting
    }
  },

  actions: {
    scan ({ commit }: ActionContext<State, RootState>): Promise<void> {
      commit('SET_DEVICES', [])
      commit('SET_SCANNING', true)

      return bluetoothApi.scan()
        .then(devices => {
          commit('SET_DEVICES', devices)
        })
        .finally(() => {
          commit('SET_SCANNING', false)
        })
    },

    connect ({ commit, dispatch }: ActionContext<State, RootState>, device: Device): Promise<void> {
      commit('SET_CONNECTED_DEVICE', null)
      commit('SET_CONNECTING', true)

      return bluetoothApi.connect(device.id)
        .then(() => {
          commit('SET_CONNECTED_DEVICE', device)
          dispatch('board/reset', null, { root: true })
        })
        .finally(() => {
          commit('SET_CONNECTING', false)
        })
    },

    write (context: ActionContext<State, RootState>, message: string): Promise<void> {
      return bluetoothApi.write(message)
    }
  }
}
