import Game from './game'

export default class Zap extends Game {
  protected hit (): void {
    this.match.activePlayer.score.add(this.match.hit.getTotal())

    if (this.match.activePlayer.score.value > 321) {
      this.goingBust()
    } else if (this.match.activePlayer.score.value === 321) {
      if (
        (this.options.MASTERS === true) &&
        !this.match.activePlayer.hasZapped
      ) {
        this.resetPlayerScore(this.match.activePlayer, 0)

        this.endPlayerRound()
      } else {
        this.setWinner(this.match.activePlayer)
      }
    } else {
      this.match.otherPlayers.forEach(player => {
        if (this.match.activePlayer.score.value === player.score.value) {
          this.match.activePlayer.hasZapped = true
          this.resetPlayerScore(player, 0)
        }
      })

      super.hit()
    }
  }

  private goingBust (): void {
    this.resetPlayerScore(this.match.activePlayer, 321 - (this.match.activePlayer.score.value - 321))

    this.endPlayerRound()
  }
}
