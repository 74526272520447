import Hit from '@/model/match/hit'
import Player from '@/model/match/player'
import Target from '@/model/match/target'
import { random, range } from 'lodash'
import Game from './game'

export default class Cricket extends Game {
  protected init (): void {
    this.match.players.forEach(player => {
      player.hits = [0, 0, 0, 0, 0, 0, 0]
    })

    this.match.targets.forEach(target => {
      target.active = true
    })

    if (this.options.CRAZY != null) {
      this.match.sectionTargets.forEach(target => {
        target.drawing = true
        target.section = this.drawNewTargetSection()
      })

      this.match.targets[6].section = 25

      setTimeout(() => {
        super.init()
      }, 2000)
    } else {
      [15, 16, 17, 18, 19, 20, 25].forEach((section, index) => {
        this.match.targets[index].section = section
      })

      super.init()
    }
  }

  protected hit (): void {
    const targetIndex = this.getTargetIndex(this.match.hit)

    if (targetIndex !== -1) {
      for (let i = 0; i < this.match.hit.coefficient; i++) {
        if (this.hasPlayerClosedTarget(this.match.activePlayer, targetIndex)) {
          switch (this.options.SCORE) {
            case 'NO_SCORE':
              break
            case 'CUT_THROAT':
              this.match.otherPlayers.forEach(player => {
                if (!this.hasPlayerClosedTarget(player, targetIndex)) {
                  player.score.value += this.match.hit.section
                }
              })
              break
            default:
              if (this.match.targets[targetIndex].active) {
                this.match.activePlayer.score.value += this.match.hit.section
              }
              break
          }
        } else {
          this.match.activePlayer.hits.splice(targetIndex, 1, this.match.activePlayer.hits[targetIndex] + 1)

          if (this.hasPlayerClosedTarget(this.match.activePlayer, targetIndex) && this.isTargetClosed(targetIndex)) {
            this.match.targets[targetIndex].active = false
          }
        }
      }

      if (this.hasPlayerWon(this.match.activePlayer)) {
        this.setWinner(this.match.activePlayer)
      } else {
        super.hit()
      }
    } else {
      super.hit()
    }
  }

  public endPlayerRound (manually = false): void {
    if (this.options.CRAZY === 'WILD_AND_CRAZY') {
      this.match.hits
        .filter(hit => hit != null)
        .map(hit => this.getTargetIndex(hit))
        .filter((hit, index, self) => self.indexOf(hit) === index)
        .forEach(targetIndex => {
          if (targetIndex !== -1 && targetIndex !== 6 &&
            !this.match.players.some(player => this.hasPlayerClosedTarget(player, targetIndex))
          ) {
            this.match.sectionTargets[targetIndex].drawing = true
            this.match.sectionTargets[targetIndex].section = this.drawNewTargetSection()
          }
        })
    }

    super.endPlayerRound(manually)
  }

  protected startPlayerRound (): void {
    this.match.randomizeTargets.forEach(target => {
      target.drawing = false
    })

    super.startPlayerRound()
  }

  protected setWinner (player: Player): void {
    let winners = [player]

    while (winners.length > 0) {
      const position = this.match.winners.length + 1

      winners.forEach(winner => {
        winner.rank = position
      })

      winners = this.match.inGamePlayers.filter(player => this.hasPlayerWon(player))
    }

    this.checkEndGame()
  }

  private drawNewTargetSection (): number {
    const availableSections = range(1, 21).filter(section => {
      return !this.match.targets.map(target => target.section).includes(section)
    })

    return availableSections[random(0, availableSections.length - 1)]
  }

  private getTargetIndex (hit: Hit): number {
    return this.match.targets.findIndex((target: Target) => target.section === hit.section)
  }

  private isTargetClosed (targetIndex: number): boolean {
    return this.match.players.every(player => this.hasPlayerClosedTarget(player, targetIndex))
  }

  private hasPlayerClosedTarget (player: Player, targetIndex: number): boolean {
    return player.hits[targetIndex] === 3
  }

  private hasPlayerWon (player: Player): boolean {
    let bestScore
    if (this.options.SCORE === 'CUT_THROAT') {
      bestScore = Math.min(...this.match.inGamePlayers.map(player => player.score.value))
    } else {
      bestScore = Math.max(...this.match.inGamePlayers.map(player => player.score.value))
    }

    return this.hasPlayerClosedAllTargets(player) && (this.match.activePlayer.score.value === bestScore)
  }

  private hasPlayerClosedAllTargets (player: Player): boolean {
    return player.hits.every(marks => marks === 3)
  }
}
