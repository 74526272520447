import Game from './game'

export default class FixRoundGame extends Game {
  protected numberOfRounds: number

  public endPlayerRound (manually = false): void {
    if (this.match.isEndRound && (this.numberOfRounds === this.match.round)) {
      this.orderWinnersByHighestScore()
    } else {
      super.endPlayerRound(manually)
    }
  }
}
