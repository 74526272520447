






















































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































import Hit from '@/model/match/hit'
import Vue, { PropType } from 'vue'

export default Vue.extend({
  name: 'DartBoard',

  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    hits: {
      type: Array as PropType<Array<Hit>>,
      default: () => []
    }
  },

  methods: {
    onHit (section: number, coefficient: number, event: MouseEvent): void {
      if (!this.disabled) {
        event.stopPropagation()
        const targetElement = event.target as HTMLElement

        if (this.hits.length < 3) {
          targetElement.classList.add('focus')
          setTimeout(() => {
            targetElement.classList.remove('focus')
          }, 500)
        }

        const parentElement = targetElement.parentElement

        this.$emit('shoot',
          new Hit(
            section,
            coefficient,
            event.offsetX / parentElement.clientWidth,
            event.offsetY / parentElement.clientHeight
          )
        )
      }
    }
  }
})
