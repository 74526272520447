import BurmaRoad from './burma-road'
import Cricket from './cricket'
import Game from './game'
import HighScore from './high-score'
import More from './more'
import Shanghai from './shanghai'
import X01 from './x01'
import Zap from './zap'

export default class GameFactory {
  public static getGame (gameCode: string): Game {
    let game: Game
    switch (gameCode) {
      case 'BURMA_ROAD':
        game = new BurmaRoad()
        break
      case 'CRICKET':
        game = new Cricket()
        break
      case 'HIGH_SCORE':
        game = new HighScore()
        break
      case 'MORE':
        game = new More()
        break
      case 'SHANGAI':
        game = new Shanghai()
        break
      case 'X01':
        game = new X01()
        break
      case 'zap':
        game = new Zap()
        break
    }
    if (game != null) {
      game.code = gameCode
    }

    return game
  }
}
