















import Vue from 'vue'

export default Vue.extend({
  name: 'BaseCheckbox',

  props: {
    value: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      default: ''
    }
  }
})
