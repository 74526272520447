import Game from '@/model/new-match/game'

export const games: Array<Game> = [
  {
    code: 'BURMA_ROAD',
    name: 'Burma Road'
  },
  {
    code: 'CRICKET',
    name: 'Cricket',
    options: [
      {
        code: 'CRAZY',
        type: 'checkbox-group',
        choices: [
          {
            text: 'Crazy',
            value: 'CRAZY'
          },
          {
            text: 'Wild And Crazy',
            value: 'WILD_AND_CRAZY'
          }
        ]
      },
      {
        code: 'SCORE',
        type: 'checkbox-group',
        choices: [
          {
            text: 'No Score',
            value: 'NO_SCORE'
          },
          {
            text: 'Cut Throat',
            value: 'CUT_THROAT'
          }
        ]
      }
    ]
  },
  {
    code: 'HIGH_SCORE',
    name: 'High Score'
  },
  {
    code: 'MORE',
    name: 'More',
    options: [
      {
        code: 'NUMBER_OF_ROUNDS',
        type: 'select',
        required: true,
        defaultValue: '10',
        choices: [
          {
            text: '5',
            value: '5'
          },
          {
            text: '10',
            value: '10'
          }
        ]
      }
    ]
  },
  {
    code: 'SHANGAI',
    name: 'Shanghai'
  },
  {
    code: 'X01',
    name: 'X01',
    options: [
      {
        code: 'SCORE',
        type: 'select',
        required: true,
        defaultValue: '301',
        choices: [
          {
            text: '101',
            value: '101'
          },
          {
            text: '301',
            value: '301'
          },
          {
            text: '501',
            value: '501'
          },
          {
            text: '701',
            value: '701'
          },
          {
            text: '801',
            value: '801'
          },
          {
            text: '901',
            value: '901'
          },
          {
            text: '1001',
            value: '1001'
          }
        ]
      },
      {
        code: 'DOUBLE_IN',
        name: 'Double In',
        type: 'checkbox'
      },
      {
        code: 'end',
        type: 'checkbox-group',
        choices: [
          {
            text: 'Double Out',
            value: 'DOUBLE_OUT'
          },
          {
            text: 'Masters',
            value: 'MASTERS'
          }
        ]
      }
    ]
  },
  {
    code: 'zap',
    name: 'Zap',
    options: [
      {
        code: 'MASTERS',
        name: 'Master',
        type: 'checkbox'
      }
    ]
  }
]
