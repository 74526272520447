




















































import BaseButton from '@/components/ui/BaseButton.vue'
import BaseFormField from '@/components/ui/BaseFormField.vue'
import BaseInput from '@/components/ui/BaseInput.vue'
import Member from '@/model/new-match/member'
import Vue, { PropType } from 'vue'

export default Vue.extend({
  name: 'NewMatchPanel',

  components: {
    BaseButton,
    BaseInput,
    BaseFormField
  },

  props: {
    value: {
      type: Array as PropType<Array<Member>>,
      required: true
    },
    position: {
      type: Number,
      required: true
    },
    deletable: {
      type: Boolean,
      default: true
    },
    nextName: {
      type: String,
      required: true
    }
  },

  data () {
    return {
      members: [] as Array<Member>
    }
  },

  watch: {
    value: {
      handler () {
        this.members = [...this.value]
      },
      immediate: true
    }
  },

  methods: {
    addMember () {
      this.members.push(new Member(this.nextName))
      this.$emit('input', this.members)
    },

    removeMember (memberIndex: number) {
      if (this.members.length === 1) {
        this.$emit('remove')
      } else {
        this.members.splice(memberIndex, 1)
        this.$emit('input', this.members)
      }
    }
  }
})
