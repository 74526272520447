import Area from '@/model/match/area'
import Game from './game'

export default class X01 extends Game {
  private static doubleInTargets: Array<Area> = [
    new Area(null, 2),
    new Area(25)
  ]

  private static doubleOutTargets: Array<Area> = [new Area(null, 2)]
  private static tripleOutTargets: Array<Area> = [new Area(null, 3)]
  private static mastersTargets: Array<Area> = [
    new Area(null, 2),
    new Area(null, 3),
    new Area(25)
  ]

  protected initPlayers (): void {
    this.match.players.forEach(player => {
      player.score.value = +this.options.SCORE
    })
  }

  protected hit (): void {
    if (
      (this.match.activePlayer.score.value < +this.options.SCORE) ||
      (this.options.DOUBLE_IN !== true) ||
      this.hitTargets(X01.doubleInTargets)
    ) {
      this.match.activePlayer.score.value -= this.match.hit.getTotal()

      if (this.match.activePlayer.score.value === 0) {
        if (
          ((this.options.end === 'DOUBLE_OUT') && !this.hitTargets(X01.doubleOutTargets)) ||
          ((this.options.end === 'TRIPLE_OUT') && !this.hitTargets(X01.tripleOutTargets)) ||
          ((this.options.end === 'MASTERS') && !this.hitTargets(X01.mastersTargets))
        ) {
          this.goingBust()
        } else {
          this.setWinner(this.match.activePlayer)
        }
      } else if (
        (this.match.activePlayer.score.value < 0) ||
        (
          (this.options.end != null) &&
          (
            ((this.options.end === 'TRIPLE_OUT') && (this.match.activePlayer.score.value < 3)) ||
            (this.match.activePlayer.score.value < 2)
          )
        )
      ) {
        this.goingBust()
      } else {
        super.hit()
      }
    } else {
      super.hit()
    }
  }

  protected hitTargets (targets: Array<Area>): boolean {
    return targets.some(target => this.match.hit.match(target))
  }

  private goingBust (): void {
    this.resetPlayerScore(this.match.activePlayer, this.match.activePlayer.score.initial)

    this.endPlayerRound()
  }
}
