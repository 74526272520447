






































































import DartBoard from '@/components/DartBoard.vue'
import NewMatchForm from '@/components/NewMatchForm/Index.vue'
import Settings from '@/components/Settings/Index.vue'
import Splash from '@/components/Splash.vue'
import Dialog from '@/components/ui/Dialog.vue'
import Hit from '@/model/match/hit'
import NewMatch from '@/model/new-match/new-match'
import Scoreboard from 'scoreboard'
import Vue from 'vue'
import { mapGetters } from 'vuex'

export default Vue.extend({
  name: 'App',

  components: {
    DartBoard,
    Dialog,
    NewMatchForm,
    Scoreboard,
    Settings,
    Splash
  },

  data () {
    return {
      splash: (process.env.VUE_APP_BUILD_TARGET === 'web') as boolean,
      bluetooth: (process.env.VUE_APP_BLUETOOTH != null) as boolean,

      newMatchDialog: false as boolean,
      settingsDialog: false as boolean
    }
  },

  computed: {
    ...mapGetters({
      match: 'match/match',
      history: 'match/history'
    })
  },

  watch: {
    match: {
      handler () {
        this.$store.dispatch('match/save')

        if (process.env.VUE_APP_BLUETOOTH === 'true') {
          this.$store.dispatch('board/updateMatch')
        }
      },
      deep: true
    }
  },

  created () {
    this.$store.dispatch('match/load')
    this.$forceUpdate()
  },

  mounted () {
    if (this.splash) {
      setTimeout(() => {
        this.splash = false
      }, 2000)
    }
  },

  methods: {
    errorCaptured () {
      this.$store.dispatch('match/reset')
    },

    openNewMatchDialog () {
      this.newMatchDialog = true
    },

    closeNewMatchDialog () {
      this.newMatchDialog = false
    },

    start (newMatch: NewMatch) {
      this.closeNewMatchDialog()

      this.$store.dispatch('match/start', newMatch)
    },

    nextPlayer (): void {
      this.$store.dispatch('match/nextPlayer')
    },

    cancel (): void {
      this.$store.dispatch('match/cancel')
      this.$forceUpdate()
    },

    shoot (hit: Hit): void {
      this.$store.dispatch('match/shoot', hit)
    },

    openSettingsDialog (): void {
      this.settingsDialog = true
    },

    closeSettingsDialog (): void {
      this.settingsDialog = false
    }
  }
})
