






















import Section from '@/model/settings/section'
import Vue from 'vue'
import About from './About.vue'
import Bluetooth from './Bluetooth.vue'
import Rules from './Rules.vue'

export default Vue.extend({
  name: 'Settings',

  components: {
    About,
    Bluetooth,
    Rules
  },

  data () {
    return {
      sections: [
        {
          code: 'rules',
          name: 'Rules'
        },
        {
          code: 'about',
          name: 'About'
        }
      ] as Array<Section>,
      selectedSection: null as null | Section
    }
  },

  created () {
    this.selectedSection = this.sections[0]

    if (process.env.VUE_APP_BLUETOOTH === 'true') {
      this.sections.splice(this.sections.length - 1, 0, {
        code: 'bluetooth',
        name: 'Bluetooth'
      })
    }
  },

  methods: {
    selectSection (section: Section): void {
      this.selectedSection = section
    }
  }
})
